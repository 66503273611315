<template>
  <div class="form-select-container">
    <select 
      :name="[Name]"
      :id="[Id]"
      :class="[className, stateName]" 
      :disabled="disabled"
      @change="$emit('input', $event.target.value)"
    >
      <option disabled value="" selected>選択してください</option>
      <option
        v-for="(item, index) in optionList"
        :key="index"
        :value="item"
      >
        {{ item }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: ["state", "type", "optionList", "disabled", "form_items", "value", "name", "id"],
  computed: {
    stateName() {
      return this.state ? `is-${this.state}` : "";
    },
    className() {
      return this.type ? `form-select--${this.type}` : "form-select";
    },
    Name() {
      return this.name ? `${this.name}` : "";
    },
    Id() {
      return this.id ? `${this.id}` : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.form-select-container {
  position: relative;
  width: 100%;
  &::after {
    content: "";
    position: absolute;
    top: 16px;
    right: 12px;
    display: block;
    width: 0;
    height: 0;
    border-width: 9px 6px 0;
    border-style: solid;
    border-color: #000 transparent transparent;
  }
  %form_select {
    width: 100%;
    height: 40px;
    border: 1px solid $c-border-2;
    font-size: 1.6rem;
    background: transparent;
    color: $c-black;
    -webkit-appearance: none;
    appearance: none;
    padding: 0 12px;
    &.is-error {
      background-color: $c-errorBg;
      border-color: #cb0101;
    }
    &.form-select.is-required {
      background-color: #fffee8;
    }
  }
  .form-select {
    @extend %form_select;
    &:disabled {
      background-color: #ebebeb;
    }
  }
  .form-select--white {
    @extend %form_select;
    background: $c-white;
  }
}
</style>
