<template>
  <!-- TODO maxlengthの実装 -->
  <textarea
    :name="name"
    :id="id"
    :class="['form-textarea', stateName]"
    :value="value"
    @input="$emit('input', $event.target.value)"  
    :maxlength="maxLengthComputed" 
  ></textarea>
</template>

<script>
export default {
  data() {
    return {
      id: "",
      name:"",
      placeholder:"",
      required:""
    };
  },
  props: {
    state: String,
    form_items: Object,
    value: [String, Number],
    maxlength: Number
  },
  computed: {
    stateName() {
      return this.state ? `is-${ this.state }` : '';
    },
    maxLengthComputed() {
      return this.maxlength ? this.maxlength : 200
    },
  }
}
</script>

<style lang="scss" scoped>
.form-textarea {
  width: 100%;
  // height: 108px;
  border: 1px solid $c-border-2;
  font-size: 1.6rem;
  line-height: 1.5;
  padding: 7px 10px;
  @media screen and (max-width: $tablet_point) {
    height: auto;
    min-height: 120px;
  }
  &.is-error {
    background-color: $c-errorBg;
    border-color: #cb0101;
  }
}
</style>